export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

declare global {
  interface Window {
    fbq: any;
  }
}

export const trackPageviewToFacebook = () => {
  if (!window?.fbq) return;
  window.fbq('track', 'PageView');
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const trackFacebookDefaultEvent = (name: string, options: any = {}) => {
  if (!window?.fbq) return;
  window.fbq('track', name, options);
};

export const trackFacebookCustomEvent = (name: string, options: any = {}) => {
  if (!window?.fbq) return;
  window.fbq('trackCustom', name, options);
};
