import { createContext, useContext, Dispatch, SetStateAction } from 'react';

type NotificationContext = {
  setNotificationDisplay: Dispatch<SetStateAction<boolean>>;
  setNotificationMessage: Dispatch<SetStateAction<string>>;
  setNotificationStatus: Dispatch<SetStateAction<'success' | 'alert'>>;
};

export const notificationContext = createContext<NotificationContext | undefined>(undefined);

export const useNotificationContext = () => {
  const context = useContext(notificationContext);
  if (context === undefined) {
    throw Error('notificationContext is undefined');
  }
  return context;
};
