import { FC, ReactElement, useEffect } from 'react';

import { useRouter } from 'next/router';

import * as fbq from '@utils_iqfu/fpixel';

const handleRouteChange = () => {
  fbq.trackPageviewToFacebook();
};

type Props = {
  children: ReactElement;
};

const FacebookPixel: FC<Props> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    // This pageview only trigger first time (it is important for Pixel to have real information)
    fbq.trackPageviewToFacebook();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return children;
};

export default FacebookPixel;
