// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

//.env.production.localの環境変数
const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NEXT_PUBLIC_APP_ENV === 'production_server' ? 0.04 : 1.0,
  beforeSend(event) {
    if (window.navigator.userAgent.indexOf('Linespider') !== -1) return null;

    if (event.exception && event.exception.values) {
      for (const exception of event.exception.values) {
        if (exception.type === 'ReferenceError' && exception.value === 'window is not defined') {
          return null;
        }
        if (/Loading CSS chunk \d+ failed\./.test(exception.value)) {
          return null;
        }
        if (exception.value === 'Failed to load client build manifest') {
          return null;
        }
      }
    }

    return event;
  },
  ignoreErrors: [
    'Network Error',
    'Failed to fetch',
    'Load failed',
    'No error message',
    'NotSupportedError',
    'Unexpected token',
    'NotAllowedError',
    'googlefc is not defined',
    'safari-web-extension://',
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
