import { FC } from 'react';

import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import { RiCloseCircleFill } from 'react-icons/ri';

import styles from './NotificationBar.module.scss';

type Props = {
  open: boolean;
  toggle: (prevState: boolean) => void;
  close: () => void;
  message: string;
  status: string;
};

const NotificationBar: FC<Props> = ({ open, toggle, close, message, status }) => {
  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => close()}>
      <RiCloseCircleFill className={styles.closeIcon} />
    </IconButton>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={(prevState) => {
        toggle(!prevState);
      }}
      onClick={() => {
        close();
      }}
    >
      <SnackbarContent
        action={action}
        className={`${styles.container} 
        ${status === 'alert' && styles.alert} 
        ${status === 'success' && styles.success}`}
        message={<span id="client-snackbar">{message}</span>}
      />
    </Snackbar>
  );
};

export default NotificationBar;
