import { FC, useEffect } from 'react';

import { useRouter } from 'next/router';

import { useRecoilState } from 'recoil';

import { pageLoadingAtom } from '@recoil_iqfu/atoms/public/page_loading';

import styles from './PageLoader.module.scss';

const PageLoader: FC = () => {
  const router = useRouter();
  const [pageLoading, setPageLoading] = useRecoilState(pageLoadingAtom);

  useEffect(() => {
    const handleStart = (url: string, { shallow }: { shallow: boolean }) => {
      if (shallow) return;
      if (url !== router.asPath) {
        setPageLoading(true);
      }
    };
    const handleComplete = () => setPageLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, []);
  return (
    <>
      {pageLoading && (
        <div className={styles.loading_container}>
          <div className={styles.ball_clip_rotate}>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

export default PageLoader;
