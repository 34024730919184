import { FC } from 'react';

import Head from 'next/head';

import { useWindowSize } from '@hooks_common/useWindowSize';

const metaTitle =
  'イクラ不動産|簡単で素早い査定と高値での不動産売却・買取を実現。おすすめの不動産会社を一覧で比較！|「自分に合った」売却・買取・査定を実現する不動産売却専門サイト';

const metaDescription =
  '「自分に合った、高値での不動産売却・買取」「どこよりも簡単で素早い査定」を実現する不動産売却専門サイト、「イクラ不動産」のページです。不動産の売却相場・価格の調査や査定が素早く・簡単にできるのはもちろん、弊社で審査して厳選した、「土地・（中古）マンション・（中古）一戸建て」の売却・買取・査定が得意な、地元のおすすめの加盟不動産会社を比較検討できます。また、「一戸建ての売却が得意な不動産屋さん」「マンションの売却に強い不動産屋さん」といった、自分に合った切り口でも不動産会社を比較・検討できます。併せて、全国の不動産売却市場・相場の動向や、売却に必要な知識から実際に合った売却事例まで、不動産売却をするにあたって必要な情報も掲載しています。';

const CommonHead: FC = ({}) => {
  const { width: windowWidth } = useWindowSize();

  const ApiDomain = process.env.NEXT_PUBLIC_DEV_BACKEND_URL!.replace('/v1', '');
  return (
    <Head>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <title key="title">{metaTitle}</title>
      <meta name="description" content={metaDescription} key="description" />
      <link rel="icon" href="/favicon.ico" />
      <meta
        name="viewport"
        content={windowWidth < 375 && windowWidth > 0 ? 'width=374' : 'width=device-width,initial-scale=1'}
      />
      <meta property="og:title" content={metaTitle} key={'og_title'} />
      <meta property="og:description" content={metaDescription} key={'og_description'} />
      <meta property="og:url" content="https://iqrafudosan.com/" key={'og_url'} />
      <meta property="og:image" content="https://iqrafudosan.s3-ap-northeast-1.amazonaws.com/img/ogp.jpg" />
      <meta property="og:type" content="article" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaTitle} key={'twitter_title'} />
      <meta name="twitter:description" content={metaDescription} key={'twitter_description'} />
      <meta name="twitter:image:src" content="https://iqrafudosan.s3-ap-northeast-1.amazonaws.com/img/ogp.jpg" />
      {process.env.NEXT_PUBLIC_APP_ENV !== 'production_server' && <meta name="robots" content="noindex" />}
      <link rel="icon" href="https://iqrafudosan.s3-ap-northeast-1.amazonaws.com/img/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="https://iqrafudosan.s3-ap-northeast-1.amazonaws.com/img/company_logo-180x180.png"
      />
      <link rel="preconnect" href={ApiDomain} />
    </Head>
  );
};

export default CommonHead;
