import { FC } from 'react';

import Script from 'next/script';

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '';
const FACEBOOK_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID || '';
declare global {
  interface Window {
    gtag: any;
    dataLayer: Record<string, unknown>[];
  }
}

export const TrackingCodes: FC = () => {
  const locationHost =
    process.env.NEXT_PUBLIC_APP_ENV === 'production_server' ? 'iqrafudosan.com' : 'v2-dev.iqrafudosan.com';
  const needsPlausible =
    process.env.NEXT_PUBLIC_APP_ENV === 'production_server' || process.env.NEXT_PUBLIC_APP_ENV === 'development_server';
  return (
    <>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${GTM_ID}');
      `,
        }}
      />
      <Script
        defer
        id="facebook-script"
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', ${FACEBOOK_ID});`,
        }}
        strategy="afterInteractive"
      />

      <script defer id="yahoo-script" src="https://s.yimg.jp/images/listing/tool/cv/ytag.js" />
      <Script
        id="yahoo-script2"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.yjDataLayer = window.yjDataLayer || [];
          function ytag() { yjDataLayer.push(arguments); }
          ytag({"type":"ycl_cookie"});
          `,
        }}
      />
      {needsPlausible && (
        <script defer data-domain={locationHost} src="https://plausible.iqra.dev/js/script.tagged-events.js"></script>
      )}
    </>
  );
};
